<template>
  <navBar v-if="showBars()" />
  <sideNav v-if="showBars()" />
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <router-view />
    <SiteFooter v-if="showBars()" />
  </main>
</template>

<script>
import sideNav from '@/components/layout/SideNav.vue'
import navBar from '@/components/layout/NavBar.vue'
import SiteFooter from '@/components/layout/SiteFooter.vue'

export default {
  name: 'App',
  components: { SiteFooter, navBar, sideNav },
  methods: {
    showBars () {
      if (['RecoverPassword', 'ResetPassword', 'SigninPage'].includes(this.$route.name)) {
        return false
      }
      return this.logged
    }
  }
}
</script>

<style scoped>
</style>

import { createApp } from 'vue'

import App from './App.vue'
import store from './store'
import router from './router'
import GlobalMixin from '@/mixins/globalMixin'
import i18n from './plugins/i18n'
import notice from './plugins/notice/notice'
import Layout from './layout'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Layout)
app.use(i18n)
app.use(notice)
app.use(mdiVue, { icons: mdijs })
app.mixin(GlobalMixin)
app.mount('#app')

<template>
  <SigninForm
    v-if="!isLogged()"
    @signin-user="signinUser"
  />
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import SigninForm from '@/views/components/signin/SigninForm.vue'
import AuthResource from '@/resources/auth.resource'

export default {
  name: 'SigninPage',
  components: { SigninForm },
  data () {
    return {
      authResource: new AuthResource()
    }
  },
  methods: {
    ...mapMutations(['setUser', 'setToken']),
    ...mapGetters(['isAdmin', 'isLogged']),
    async signinUser(loginData) {
      const signedIn = await this.authResource.signin(loginData)

      if (!signedIn) {
        await this.$notice.alert(this.$t('messages.errorMessage'));
        return
      }

      const { user, token } = signedIn;
      if (!user || !token) {
        await this.$notice.alert(this.$t('messages.errorMessage'))
        return
      }

      this.setUser(user)
      this.setToken(token)

      if (this.isAdmin()) {
        window.location = '/'
        return
      }
      window.location = `/private/user/${user.id}/profile`
    }
  }
}
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4" />
    <div class="row">
      <div class="card">
        <div class="pb-0 card-header">
          <div class="row">
            <div class="col-lg-8 p-3 d-flex justify-content-between">
              <h6 class="mb-2">
                {{ $t('messages.tags') }}
              </h6>
            </div>
            <div class="col-lg-4 d-flex align-items-center mr-4">
              <button
                class="btn mb-0 btn-success ms-auto"
                @click="enableCreate"
              >
                <mdicon name="tag-plus-outline" />
                {{ $t('messages.createTag') }}
              </button>
            </div>
          </div>
        </div>
        <contentPlaceholder v-if="loading" />
        <div
          v-else
          class="table"
        >
          <div class="table-row">
            <div class="px-2 py-1 row">
              <div class="col">
                <p class="mb-0 text-sm font-weight-bold">
                  {{ $t('messages.tagName') }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div
              v-for="currentTag in items"
              :key="currentTag.id"
            >
              <div class="table-row">
                <div class="px-2 py-1 row">
                  <div class="col">
                    <h6 class="mb-0 text-sm">
                      {{ currentTag.name }}
                    </h6>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <a
                      class="btn btn-link btn-sm text-success"
                      :title="$t('messages.editTag')"
                      @click="selectItem(currentTag)"
                    >
                      <mdicon name="tag-edit-outline" />
                    </a>
                    <a
                      class="btn btn-link btn-sm text-success"
                      :href="`/${baseDir}/tag/${currentTag.id}/menu-items`"
                      :title="$t('messages.tagRelatedMenuItems')"
                    >
                      <mdicon name="invoice-text-multiple-outline" />
                    </a>
                    <a
                      class="btn btn-link text-danger"
                      :title="$t('messages.deleteTag')"
                      @click="deleteTag(currentTag)"
                    >
                      <mdicon name="tag-remove-outline" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        v-if="showPagination"
        v-model="paging.page"
        :loading="loading"
        :total-rows="total"
        :per-page="paging.limit"
        class="mt-3 mb-3"
        :value="paging.page"
        @set-page="setPage"
      />
    </div>
    <ModalPopup ref="upsertTag">
      <UpsertTag
        :tag="tag"
        @create-tag="createTag"
        @update-tag="updateTag"
        @hide-modals="hideModals"
      />
    </ModalPopup>
  </div>
</template>

<script>
import ContentPlaceholder from '@/components/layout/ContentPlaceholder.vue'
import Pagination from '@/components/common/Pagination.vue'
import store from '@/store'
import UpsertTag from '@/views/components/tag/UpsertTag.vue'
import Tag from '@/models/tag'
import TagResource from '@/resources/tag.resource'
import ModalPopup from '@/components/common/ModalPopup.vue'

export default {
  name: "TagList",
  components: {
    ModalPopup,
    ContentPlaceholder,
    UpsertTag,
    Pagination
  },
  data() {
    return {
      loading: true,
      items: null,
      total: 0,
      tag: null,
      paging: { page: 1, limit: 20, sort: 'name', sortType: 'desc' },
      showPagination: false,
      tagResource: new TagResource()
    }
  },
  mounted () {
    this.selectItems()
  },
  methods: {
    async selectItems () {
      if (store.getters.isAdmin) {
        await this.getTags()
      }
    },
    async getTags () {
      const response = await this.tagResource.getTags(this.paging)
      if (response) {
        this.loading = false
        this.items = response.data
        this.total = response.total
        if (this.total > this.paging.limit) {
          this.showPagination = true
        }
      }
    },
    async createTag (tagData) {
      try {
        const response = await this.tagResource.createTag(tagData)
        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'));
          return
        }
        this.hideModals()
        this.appendItemOnList(response.data)
      } catch (e) {
        await this.$notice.alert(this.$t('messages.errorMessage'));
      }
    },
    async updateTag (tagData) {
      try {
        const response = await this.tagResource.updateTag(tagData)
        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'))
          return false
        }
        this.hideModals()
        this.updateItemOnList(response.data)
      } catch (e) {
        await this.$notice.alert(this.$t('messages.errorMessage'))
      }
    },
    async deleteTag (tag) {
      await this.$notice.confirm(this.$t('messages.areYouSure')).then(async (res) => {
        if (res) {
          try {
            const response = await this.tagResource.deleteTag(tag.id)
            if (!response) {
              await this.$notice.alert(this.$t('messages.errorMessage'))
            }
            await this.$notice.toast.success(this.$t('messages.deletedMessage'));
            this.hideModals()
            this.removeItemFromList(tag)
          } catch (e) {
            await this.$notice.alert(this.$t('messages.errorMessage'))
          }
        }
      })
    },
    selectItem (tag) {
      this.tag = new Tag(tag)
      this.$refs.upsertTag.openModal()
    },
    enableCreate () {
      this.tag = null
      this.tag = new Tag()
      this.$refs.upsertTag.openModal()
    },
    hideModals () {
      this.$refs.upsertTag.closeModal()
    },
    setPage (page) {
      this.paging.page = page
      this.selectItems()
    }
  }
};
</script>

<style scoped>
.table :not(:first-child) {
  border: none;
}
.table-row {
  border-bottom: 1px solid #D5D8DC !important;
}
.table-row:hover {
  background-color: #E5E4E2;
}
.col > .btn {
  margin-bottom: 0;
}
</style>

import { MenuSectionStatus } from '@/utils/enums/menuSectionEnum'

export default class MenuSection {
    constructor (data) {
        if (!data) {
            this.id = null
            this.menuId = null
            this.title = null
            this.description = null
            this.footNote = null
            this.position = null
            this.status = MenuSectionStatus.active
            return
        }
        this.id = data.id
        this.menuId = data.menuId
        this.title = data.title
        this.description = data.description
        this.footNote = data.footNote
        this.position = data.position
        this.status = data.status
    }
}

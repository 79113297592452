<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6 v-if="tag?.id">
        {{ $t('messages.editTag') }}
      </h6>
      <h6 v-else>
        {{ $t('messages.createTag') }}
      </h6>
    </div>
    <div class="card-body">
      <TavernoForm
        v-slot="{errors}"
        :validation-schema="tagSchema"
        type="POST"
        @submit="upsertTag"
      >
        <div class="form-group">
          <label class="form-label">{{ $t('messages.tagName') }}</label>
          <Field
            v-model="tagTitle"
            name="name"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.tagName}"
            :placeholder="$t('messages.tagName')"
            aria-label="Name"
          />
          <ErrorMessage
            class="text-danger small"
            name="name"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.tagDescription') }}</label>
          <Field
            v-model="tagDescription"
            name="description"
            as="textarea"
            class="form-control"
            :class="{'is-invalid':errors.tagDescription}"
            :placeholder="$t('messages.tagDescription')"
            aria-label="Description"
          />
          <ErrorMessage
            class="text-danger small"
            name="description"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.tagClassName') }}</label>
          <Field
            v-model="tagClassName"
            name="class"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.tagClassName}"
            :placeholder="$t('messages.tagClassName')"
            aria-label="Class name"
          />
          <ErrorMessage
            class="text-danger small"
            name="class"
          />
        </div>
        <div class="text-center row">
          <div class="col">
            <button
              type="submit"
              role="button"
              class="my-4 mb-2 btn btn-primary"
            >
              <mdicon name="content-save-check-outline" />
              {{ $t('messages.save') }}
            </button>
            <a
              role="button"
              class="my-4 mb-2 mx-2 btn btn-success"
              @click="cancel"
            >
              <mdicon name="content-save-off-outline" />
              &nbsp;{{ $t('messages.cancel') }}
            </a>
          </div>
        </div>
      </TavernoForm>
    </div>
  </div>
</template>
<script>
import { Form as TavernoForm, Field, ErrorMessage } from 'vee-validate'
import { object, string } from 'yup'
import Tag from '@/models/tag'

export default {
  name: 'UpsertTag',
  components: { TavernoForm, ErrorMessage, Field },
  props: {
    tag: Tag
  },
  emits: ['create-tag', 'update-tag', 'hide-modals'],
  data() {
    const tagSchema = object({
      name: string().required(this.$t('messages.errors.requiredField')),
      description: string().required(this.$t('messages.errors.requiredField')),
      class: string().required(this.$t('messages.errors.requiredField'))
    })
    return {
      tagSchema
    }
  },
  computed: {
    tagTitle: {
      get: function () {
        return this.tag?.name
      },
      set: function (title) {
        return title
      }
    },
    tagDescription: {
      get: function () {
        return this.tag?.description
      },
      set: function (description) {
        return description
      }
    },
    tagClassName: {
      get: function () {
        return this.tag?.className
      },
      set: function (className) {
        return className
      }
    }
  },
  methods: {
    async upsertTag (tagData) {
      if (!this.tag?.id) {
        await this.createTag(tagData)
        return
      }
      await this.updateTag(tagData)
    },
    async createTag (tagData) {
      let tag = {
        name: tagData.name,
        description: tagData.description,
        className: tagData.name
      }
      this.$emit('create-tag', tag)
    },
    async updateTag (tagData) {
      let tag = {
        id: this.tag.id,
        name: tagData.name,
        description: tagData.description,
        className: tagData.class
      }
      this.$emit('update-tag', tag)
    },
    cancel () {
      this.$emit('hide-modals')
    }
  }
}
</script>

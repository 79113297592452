<template>
  <ResetPasswordForm />
</template>

<script>

import ResetPasswordForm from '@/views/components/resetPassword/ResetPasswordForm.vue'

export default {
  name: 'ResetPassword',
  components: { ResetPasswordForm }
}
</script>

<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6 v-if="user?.id">
        {{ $t('messages.editUser') }}
      </h6>
      <h6 v-else>
        {{ $t('messages.createUser') }}
      </h6>
    </div>
    <div class="card-body">
      <TavernoForm
        v-slot="{errors}"
        :validation-schema="userSchema"
        type="POST"
        @submit="createUser"
      >
        <div class="form-group">
          <label class="form-label">{{ $t('messages.name') }}</label>
          <Field
            name="name"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.name}"
            :placeholder="$t('messages.name')"
            aria-label="Name"
          />
          <ErrorMessage
            class="text-danger small"
            name="name"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.email') }}</label>
          <Field
            name="email"
            type="email"
            class="form-control"
            :class="{'is-invalid':errors.email}"
            :placeholder="$t('messages.email')"
            :disabled="!admin"
            aria-label="Email"
          />
          <ErrorMessage
            class="text-danger small"
            name="email"
          />
        </div>
        <div
          v-if="!user?.id"
          class="form-group"
        >
          <label class="form-label">{{ $t('messages.password') }}</label>
          <Field
            name="password"
            type="password"
            class="form-control"
            :class="{'is-invalid':errors.password}"
            :placeholder="$t('messages.password')"
            aria-label="Password"
          />
          <ErrorMessage
            class="text-danger small"
            name="password"
          />
        </div>
        <div
          v-if="admin"
          class="form-group"
        >
          <label class="form-label">{{ $t('messages.userType') }}</label>
          <Field
            name="type"
            as="select"
            class="form-control"
            :class="{'is-invalid':errors.userType}"
            :placeholder="$t('messages.userType')"
            :disabled="!admin"
            :value="typeList.user"
            aria-label="Type"
          >
            <option
              v-for="item in typeList"
              :key="item"
              :value="item"
            >
              {{ $t('messages.type.' + item) }}
            </option>
          </Field>
          <ErrorMessage
            class="text-danger small"
            name="type"
          />
        </div>
        <div
          v-if="admin"
          class="form-group"
        >
          <label class="form-label">{{ $t('messages.userStatus') }}</label>
          <Field
            name="status"
            as="select"
            class="form-control"
            :class="{'is-invalid':errors.userStatus}"
            :placeholder="$t('messages.userStatus')"
            :disabled="!admin"
            :value="statusList.active"
            aria-label="Status"
          >
            <option
              v-for="item in statusList"
              :key="item"
              :value="item"
            >
              {{ $t('messages.status.' + item) }}
            </option>
          </Field>
          <ErrorMessage
            class="text-danger small"
            name="status"
          />
        </div>
        <div class="text-center row">
          <div class="col">
            <button
              role="button"
              class="my-4 mb-2 btn btn-primary"
            >
              <mdicon name="content-save-check-outline" />
              &nbsp;&nbsp;{{ $t('messages.save') }}
            </button>

            <a
              role="button"
              class="my-4 mb-2 mx-2 btn btn-success"
              @click="cancel"
            >
              <mdicon name="content-save-off-outline" />
              &nbsp;{{ $t('messages.cancel') }}
            </a>
          </div>
        </div>
      </TavernoForm>
    </div>
  </div>
</template>

<script>
import { Form as TavernoForm, Field, ErrorMessage } from 'vee-validate'
import { object, string } from 'yup'
import { UserStatus, UserType } from '@/utils/enums/userEnum.js'
import User from '@/models/user.js'

export default {
  name: 'UpsertUser',
  components: { TavernoForm, Field, ErrorMessage },
  props: { user: User },
  emits: ['create-user', 'update-user', 'hide-modals'],
  data () {
    const userSchema = object( {
      name: string().required(this.$t('messages.errors.requiredField')),
      email: string().required(this.$t('messages.errors.requiredField'))
          .email(this.$t('messages.errors.fieldMustBeType', { type: 'email' })),
      password: string().required(this.$t('messages.errors.requiredField'))
          .min(7, this.$t('messages.errors.minLength', { n: 7}))
    })
    return {
      userSchema,
      statusList: UserStatus,
      typeList: UserType
    }
  },
  methods: {
    async createUser(userData) {
      let user = {
        email: userData.email,
        password: userData.password,
        name: userData.name,
        status: userData.status,
        type: userData.type
      }
      this.$emit('create-user', user)
    },
    cancel () {
      this.$emit('hide-modals')
    }
  }
}
</script>

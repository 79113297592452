import { UserStatus, UserType } from '@/utils/enums/userEnum'

export default class User {
    constructor (data) {
        if (!data) {
            this.id = null
            this.email = null
            this.password = null
            this.name = null
            this.status = UserStatus.incoming
            this.type = UserType.user
            this.createdAt = null
            return
        }
        this.id = data.id
        this.email = data.email
        this.name = data.name
        this.status = data.status
        this.type = data.type
        this.createdAt = data.createdAt
    }
}

let closeMenu = function(){
    document.getElementById("app").classList.add("g-sidenav-show")
    document.getElementById("close-menu").classList.add("d-none")
}

let showMenu = function() {
    document.getElementById("app").classList.remove("g-sidenav-show")
    document.getElementById("close-menu").classList.remove("d-none")
}

window.onload = function() {
    document.getElementById("app").classList.add("g-sidenav-show")
    if (document.getElementById("show-navbar")) {
        document.getElementById("show-navbar").addEventListener("click", showMenu)
    }

    if (document.getElementById("close-menu")) {
        document.getElementById("close-menu").addEventListener("click", closeMenu)

        const navLinks = document.getElementsByClassName('nav-link')

        navLinks.forEach(function(item) {
            item.addEventListener('click', closeMenu)
        })
    }
}

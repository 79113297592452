<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6 v-if="menuSection?.id">
        {{ $t('messages.editMenuSection') }}
      </h6>
      <h6 v-else>
        {{ $t('messages.createMenuSection') }}
      </h6>
    </div>
    <div class="card-body">
      <TavernoForm
        v-slot="{errors}"
        :validation-schema="menuSectionSchema"
        type="POST"
        @submit="upsertMenuSection"
      >
        <div class="form-group">
          <label class="form-label">{{ $t('messages.menuSectionName') }}</label>
          <Field
            v-model="menuSectionTitle"
            name="name"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.menuSectionName}"
            :placeholder="$t('messages.menuSectionName')"
            aria-label="Name"
          />
          <ErrorMessage
            class="text-danger small"
            name="name"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.menuDescription') }}</label>
          <TextEditor
            ref="description"
            :content="menuSection?.description"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.menuFootNote') }}</label>
          <TextEditor
            ref="footNote"
            :content="menuSection?.footNote"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.statusSelect') }}</label>
          <Field
            v-model="menuSectionStatus"
            name="status"
            as="select"
            class="form-control"
            :value="menuSection?.status"
            :class="{'is-invalid':errors.menuStatus}"
            :placeholder="$t('messages.statusSelect')"
            aria-label="Status"
          >
            <option
              v-for="item in statusList"
              :key="item"
              :value="item"
            >
              {{ $t('messages.status.' + item) }}
            </option>
          </Field>
        </div>
        <div class="text-center row">
          <div class="col">
            <button
              type="submit"
              role="button"
              class="my-4 mb-2 btn btn-primary"
            >
              <mdicon name="content-save-check-outline" />
              &nbsp;{{ $t('messages.save') }}
            </button>
            <a
              role="button"
              class="my-4 mb-2 mx-2 btn btn-success"
              @click="cancel"
            >
              <mdicon name="content-save-off-outline" />
              &nbsp;{{ $t('messages.cancel') }}
            </a>
          </div>
        </div>
      </TavernoForm>
    </div>
  </div>
</template>
<script>
import { Field, ErrorMessage, Form as TavernoForm } from 'vee-validate'
import { MenuSectionStatus } from '@/utils/enums/menuSectionEnum.js'
import { object, string } from 'yup'
import TextEditor from '@/components/common/TextEditor.vue'
import MenuSection from '@/models/menuSection'

export default {
  name: 'UpsertMenuSection',
  components: { TavernoForm, TextEditor, ErrorMessage, Field },
  props: {
    menuSection: MenuSection,
    menuId: { type: String, required: true }
  },
  emits: ['create-menu-section', 'update-menu-section', 'hide-modals'],
  data() {
    const menuSectionSchema = object({
      name: string().required(this.$t('messages.errors.requiredField')),
      status: string().optional()
    })
    return {
      menuSectionSchema,
      statusList: MenuSectionStatus
    }
  },
  computed: {
    menuSectionTitle: {
      get: function () {
        return this.menuSection?.title
      },
      set: function (title) {
        return title
      }
    },
    menuSectionStatus: {
      get: function () {
        return this.menuSection?.status
      },
      set: function (status) {
        return status
      }
    }
  },
  methods: {
    async upsertMenuSection (menuSectionData) {
      if (!this.menuSection.id) {
        await this.createMenuSection(menuSectionData)
        return
      }
      await this.updateMenuSection(menuSectionData)
    },
    async createMenuSection (menuSectionData) {
      let menuSection = {
        title: menuSectionData.name,
        description: this.$refs.description.getContent(),
        footNote: this.$refs.footNote.getContent(),
        menuId: this.menuId,
        position: '0'
      }
      this.$emit('create-menu-section', menuSection)
      this.resetEditors()
    },
    async updateMenuSection (menuSectionData) {
      let menuSection = {
        id: this.menuSection.id,
        title: menuSectionData.name,
        description: this.$refs.description.getContent(),
        footNote: this.$refs.footNote.getContent(),
        status: menuSectionData.status
      }
      this.$emit('update-menu-section', menuSection)
      this.resetEditors()
    },
    resetEditors () {
      this.$refs.description.resetContent()
      this.$refs.footNote.resetContent()
    },
    cancel () {
      this.$emit('hide-modals')
      this.resetEditors()
    }
  }
}
</script>

<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6 v-if="timeTable.id">
        {{ $t('messages.editTimeTable') }}
      </h6>
      <h6 v-else>
        {{ $t('messages.createTimeTable') }}
      </h6>
    </div>
    <div class="card-body">
      <ValidationForm
        v-slot="{errors}"
        :validation-schema="timeTableSchema"
        type="POST"
        @submit="upsertTimeTable"
      >
        <div class="row">
          <div class="col-6 form-group">
            <label class="form-label">{{ $t('messages.daySelect') }}</label>
            <Field
              v-model="timeTableDay"
              name="day"
              as="select"
              class="form-control"
              :value="timeTable.day"
              :class="{'is-invalid':errors.timeTableDay}"
              :placeholder="$t('messages.daySelect')"
              aria-label="Day"
            >
              <option
                :selected="!timeTable.day"
                value=""
              >
                {{ $t('messages.daySelect') }}
              </option>
              <option
                v-for="item in dayList"
                :key="item"
                :value="item"
              >
                {{ $t('messages.weekDay.' + item) }}
              </option>
            </Field>
            <ErrorMessage
              class="text-danger small"
              name="day"
            />
          </div>

          <div class="col-6 form-group">
            <label class="form-label">{{ $t('messages.statusSelect') }}</label>
            <Field
              v-model="timeTableStatus"
              name="status"
              as="select"
              class="form-control"
              :value="timeTable.status"
              :class="{'is-invalid':errors.timeTableStatus}"
              :placeholder="$t('messages.statusSelect')"
              aria-label="Status"
            >
              <option
                :selected="!timeTable.status"
                value=""
              >
                {{ $t('messages.statusSelect') }}
              </option>
              <option
                v-for="item in statusList"
                :key="item"
                :value="item"
              >
                {{ $t('messages.timeTableStatus.' + item) }}
              </option>
            </Field>
            <ErrorMessage
              class="text-danger small"
              name="status"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-3 form-group">
            <label class="form-label">{{ $t('messages.timeTableFrom') }}</label>
            <Field
              v-model="timeTableFromHour"
              name="fromHour"
              as="select"
              class="form-control"
              :value="timeTable.from.hour"
              :class="{'is-invalid':errors.timeTableFrom}"
              :placeholder="$t('messages.select')"
              aria-label="FromHour"
            >
              <option
                :selected="!timeTable.from.hour"
                value=""
              >
                {{ $t('messages.select') }}
              </option>
              <option
                v-for="item in timeTableHour"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </Field>
            <ErrorMessage
              class="text-danger small"
              name="Status"
            />
          </div>

          <div class="col-3 form-group">
            <label class="form-label">&nbsp;</label>
            <Field
              v-model="timeTableFromQuarter"
              name="fromQuarter"
              as="select"
              class="form-control"
              :value="timeTable.from.quarter"
              :class="{'is-invalid':errors.timeTableFrom}"
              :placeholder="$t('messages.select')"
              aria-label="FromQuarter"
            >
              <option
                :selected="!timeTable.from.quarter"
                value=""
              >
                {{ $t('messages.select') }}
              </option>
              <option
                v-for="item in timeTableQuarter"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </Field>
            <ErrorMessage
              class="text-danger small"
              name="FromQuarter"
            />
          </div>

          <div class="col-3 form-group">
            <label class="form-label">{{ $t('messages.timeTableTo') }}</label>
            <Field
              v-model="timeTableToHour"
              name="toHour"
              as="select"
              class="form-control"
              :value="timeTable.to.hour"
              :class="{'is-invalid':errors.timeTableTo}"
              :placeholder="$t('messages.select')"
              aria-label="ToHour"
            >
              <option
                :selected="!timeTable.to.hour"
                value=""
              >
                {{ $t('messages.select') }}
              </option>
              <option
                v-for="item in timeTableHour"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </Field>
            <ErrorMessage
              class="text-danger small"
              name="ToHour"
            />
          </div>

          <div class="col-3 form-group">
            <label class="form-label">&nbsp;</label>
            <Field
              v-model="timeTableToQuarter"
              name="toQuarter"
              as="select"
              class="form-control"
              :value="timeTable.to.quarter"
              :class="{'is-invalid':errors.timeTableTo}"
              :placeholder="$t('messages.select')"
              aria-label="ToQuarter"
            >
              <option
                :selected="!timeTable.from.quarter"
                value=""
              >
                {{ $t('messages.select') }}
              </option>
              <option
                v-for="item in timeTableQuarter"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </Field>
            <ErrorMessage
              class="text-danger small"
              name="ToQuarter"
            />
          </div>
        </div>

        <div class="row">
          <div class="text-center row">
            <div class="col">
              <button
                type="submit"
                role="button"
                class="my-4 mb-2 btn btn-primary"
              >
                <mdicon name="content-save-check-outline" />
              &nbsp;{{ $t('messages.save') }}
              </button>
              <a
                role="button"
                class="my-4 mb-2 mx-2 btn btn-success"
                @click="cancel"
              >
                <mdicon name="content-save-off-outline" />
                &nbsp;{{ $t('messages.cancel') }}
              </a>
            </div>
          </div>
        </div>
      </ValidationForm>
    </div>
  </div>
</template>
<script>
import { Form as ValidationForm, Field, ErrorMessage } from 'vee-validate'
import { mixed, object } from 'yup'
import TimeTable from '@/models/timeTable'
import { TimeTableDay, TimeTableDayOrder, TimeTableStatus, TimeTableHour, TimeTableQuarter } from '@/utils/enums/timeTableEnum'

export default {
  name: 'UpsertTimeTable',
  components: { ValidationForm, ErrorMessage, Field },
  props: {
    timeTable: TimeTable,
    tavernId: { type: String, required: true }
  },
  emits: ['create-time-table', 'update-time-table', 'hide-forms'],
  data () {
    const timeTableDays = Object.values(TimeTableDay)
    const timeTableStatus = Object.values(TimeTableStatus)
    const timeTableSchema = object({
      day: mixed().required(this.$t('messages.daySelect')).oneOf(timeTableDays, this.$t('messages.daySelect')),
      status: mixed().required(this.$t('messages.statusSelect')).oneOf(timeTableStatus, this.$t('messages.statusSelect'))
    })
    return {
      timeTableSchema,
      statusList: TimeTableStatus,
      dayList: TimeTableDay,
      timeTableHour: TimeTableHour,
      timeTableQuarter: TimeTableQuarter
    }
  },
  computed: {
    timeTableDay: {
      get: function () {
        return this.timeTable.day
      },
      set: function (day) {
        return day
      }
    },
    timeTableStatus: {
      get: function () {
        return this.timeTable.status
      },
      set: function (status) {
        return status
      }
    },
    timeTableFromHour: {
      get: function () {
        return this.timeTable.from.hour
      },
      set: function (fromHour) {
        return fromHour
      }
    },
    timeTableFromQuarter: {
      get: function () {
        return this.timeTable.from.quarter
      },
      set: function (fromQuarter) {
        return fromQuarter
      }
    },
    timeTableToHour: {
      get: function () {
        return this.timeTable.to.hour
      },
      set: function (toHour) {
        return toHour
      }
    },
    timeTableToQuarter: {
      get: function () {
        return this.timeTable.to.quarter
      },
      set: function (toQuarter) {
        return toQuarter
      }
    },
  },
  methods: {
    async upsertTimeTable (timeTableData) {
      if (!this.timeTable.id) {
        await this.createTimeTable(timeTableData)
        return
      }
      await this.updateTimeTable(timeTableData)
    },
    async createTimeTable (timeTableData) {
      let timeTable = {
        tavernId: this.tavernId,
        day: timeTableData.day,
        dayPosition: TimeTableDayOrder[timeTableData.day],
        status: timeTableData.status,
        from: TimeTable.constructHour(timeTableData.fromHour, timeTableData.fromQuarter),
        to: TimeTable.constructHour(timeTableData.toHour, timeTableData.toQuarter)
      }
      this.$emit('create-time-table', timeTable)
    },
    async updateTimeTable (timeTableData) {
      let timeTable = {
        id: this.timeTable.id,
        day: timeTableData.day,
        dayPosition: TimeTableDayOrder[timeTableData.day],
        status: timeTableData.status,
        from: TimeTable.constructHour(timeTableData.fromHour, timeTableData.fromQuarter),
        to: TimeTable.constructHour(timeTableData.toHour, timeTableData.toQuarter)
      }
      this.$emit('update-time-table', timeTable)
    },
    cancel () {
      this.$emit('hide-forms')
    }
  }
}
</script>

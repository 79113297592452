<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <contentPlaceholder v-if="loading" />
      <ProfileUser
        v-else
        :user="user"
        :user-schema="userSchema"
        @update-user="updateUser"
      />
    </div>
  </div>
</template>

<script>
import contentPlaceholder from '@/components/layout/ContentPlaceholder.vue'
import ProfileUser from '@/views/components/user/ProfileUser.vue'
import UserResource from '@/resources/user.resource'
import User from '@/models/user'
import { object, string } from 'yup'

export default {
  name: 'ProfilePage',
  components: {
    ProfileUser,
    contentPlaceholder
  },
  data () {
    const userSchema = object({
      name: string().required(this.$t('messages.errors.requiredField'))
    })
    return {
      loading: true,
      user: null,
      userResource: new UserResource(),
      userSchema
    }
  },
  mounted () {
    const userId = this.$route.params?.userId ?  this.$route.params?.userId : null
    this.selectUser(userId)
  },
  methods: {
    async selectUser(userId) {
      const response = await this.userResource.getUser(userId)
      if (response) {
        this.loading = false
        this.user = new User(response)
      }
    },
    async updateUser (userData) {
      try {
        const response = await this.userResource.updateUser(userData)
        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'))
          return false
        }
        await this.$notice.toast.info(this.$t('messages.updateMessage'))
      } catch (e) {
        await this.$notice.alert(this.$t('messages.errorMessage'))
        console.warn(e)
      }
    },
  }
}
</script>

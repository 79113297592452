<template>
  <main class="mt-0 main-content">
    <div class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg">
      <div class="container">
        <div class="row justify-content-center" />
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div v-if="loading">
            <ContentPlaceholder />
          </div>
          <div
            v-else
            class="card z-index-0"
          >
            <div class="card-header text-center pt-4">
              <h4>{{ $t('messages.resetPassword') }}</h4>
            </div>
            <div class="card-body">
              <ValidationForm
                v-if="!processed"
                v-slot="{errors}"
                :validation-schema="schema"
                type="POST"
                @submit="resetPassword"
              >
                <div class="form-group">
                  <Field
                    name="password"
                    type="password"
                    class="form-control"
                    :class="{'is-invalid':errors.password}"
                    :placeholder="$t('messages.newPassword')"
                    aria-label="Password"
                  />
                  <ErrorMessage
                    class="text-danger small"
                    name="password"
                  />
                </div>
                <div class="form-group">
                  <Field
                    name="confirm"
                    type="password"
                    class="form-control"
                    :class="{'is-invalid':errors.confirm}"
                    :placeholder="$t('messages.confirmPassword')"
                    aria-label="confirm"
                  />
                  <ErrorMessage
                    class="text-danger small"
                    name="confirm"
                  />
                </div>

                <div class="text-center">
                  <button
                    type="submit"
                    role="button"
                    class="my-4 mb-2 btn btn-primary w-100"
                  >
                    {{ $t('messages.send') }}
                  </button>
                </div>
              </ValidationForm>
              <div v-else>
                <p>{{ $t('messages.youCanLoginNow') }}</p>
                <p class="text-center">
                  <a
                    href="/"
                    class="text-success"
                  >{{ $t('messages.signIn') }}</a>
                </p>
              </div>
              <div class="px-1 pt-0 text-center card-footer px-lg-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Form as ValidationForm, Field, ErrorMessage } from 'vee-validate'
import { object, string, ref } from 'yup'
import { mapMutations } from 'vuex'
import AuthResource from '@/resources/auth.resource'
import ContentPlaceholder from '@/components/layout/ContentPlaceholder.vue'

export default {
  name: 'ResetPassword',
  components: {ContentPlaceholder, ValidationForm, Field, ErrorMessage },
  emits: ['reset-user-password'],
  data() {
    const schema = object({
      password: string().required(this.$t('messages.errors.requiredField'))
                .min(7, this.$t('messages.errors.minLength', { n: 7})),
      confirm: string().required(this.$t('messages.errors.requiredField'))
                .oneOf([ref('password')], this.$t('messages.errors.passwordsDontMatch')),
    })
    return {
      schema,
      authResource: new AuthResource(),
      processed: false,
      loading: false
    }
  },
  methods: {
    ...mapMutations(['unsetUser', 'unsetToken', 'signOut']),
    async resetPassword(resetData) {
      this.loading = true
      const token = this.$route.query?.reason ?  this.$route.query.reason : null
      if (!token) {
        await this.$notice.alert(this.$t('messages.errorMessage'));
        this.loading = false
        return
      }

      const data = {
        password: resetData.password,
        token
      }

      const response = await this.authResource.resetPassword(data)

      if (!response) {
        await this.$notice.alert(this.$t('messages.errorMessage'))
        this.loading = false
        return
      }

      this.loading = false
      this.processed = true
      this.signOut()
      // await this.$notice.alert(this.$t('messages.youCanLoginNow'))
    }
  }
}
</script>

<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="form-modal-label"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'

export default {
  name: 'ModalPopup',
  props: { id: { type: String, required: false, default: 'form-modal' }},
  data() {
    return {
      modal: null,
      opened: false
    }
  },
  mounted () {
    this.modal = new Modal('#' + this.id)
  },
  methods: {
    openModal () {
      this.modal.show()
      this.opened = true
    },
    closeModal () {
      this.modal.hide()
      this.opened = false

    }
  }
}
</script>

<style>
</style>

<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4" />
    <div class="row">
      <div class="card">
        <div class="pb-0 card-header">
          <div class="row">
            <a
              class="btn btn-link btn-sm text-black-30"
              :title="$t('messages.back')"
              @click="$router.go(-1)"
            >
              <mdicon name="arrow-left" />
              {{ $t('messages.back') }}
            </a>
          </div>
          <div class="row">
            <div class="col-lg d-flex justify-content-between">
              <h6 class="mb-2">
                {{ $t('messages.myMenuSections') }}
              </h6>
            </div>
            <div class="col-lg d-flex align-items-center mr-4">
              <button
                v-if="!$refs.upsertMenuSection?.opened"
                class="btn mb-0 btn-success ms-auto"
                @click="enableCreate"
              >
                <mdicon name="text-box-plus-outline" />
                {{ $t('messages.createMenuSection') }}
              </button>
            </div>
          </div>
        </div>
        <contentPlaceholder v-if="loading" />
        <div
          v-else
          class="table"
        >
          <div class="table-row">
            <div class="px-2 py-1 row">
              <div class="col">
                <p class="mb-0 text-sm font-weight-bold">
                  {{ $t('messages.menuSectionName') }}
                </p>
              </div>
            </div>
          </div>
          <Draggable
            v-model="items"
            tag="div"
            item-key="id"
            class="table"
            @end="moved($event, items)"
          >
            <template #item="{ element: currentMenuSection }">
              <div class="table-row">
                <div class="px-2 py-1 row">
                  <div class="col">
                    <h6 class="mb-0 text-sm title">
                      {{ currentMenuSection.title }}
                    </h6>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <a
                      class="btn btn-link text-success"
                      :title="$t('messages.editMenuSection')"
                      @click="selectItem(currentMenuSection)"
                    >
                      <mdicon name="text-box-edit-outline" />
                    </a>
                    <a
                      class="btn btn-link text-success"
                      :href="`/${baseDir}/section/${currentMenuSection.id}/items`"
                      :title="$t('messages.seeMenuItems')"
                    >
                      <mdicon name="invoice-text-multiple-outline" />
                    </a>
                    <a
                      class="btn btn-link text-danger"
                      :title="$t('messages.deleteMenuSection')"
                      @click="deleteMenuSection(currentMenuSection)"
                    >
                      <mdicon name="text-box-remove-outline" />
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </Draggable>
          <div class="d-flex justify-content-end p-4">
            <button
              v-if="showOrderButton()"
              class="btn mb-0 btn-info ms-auto"
              @click="updatePositions"
            >
              <mdicon name="sort" />
              {{ $t('messages.saveOrdering') }}
            </button>
          </div>
        </div>
      </div>
      <Pagination
        v-if="showPagination"
        v-model="paging.page"
        :loading="loading"
        :total-rows="total"
        :per-page="paging.limit"
        class="mt-3 mb-3"
        :value="paging.page"
        @set-page="setPage"
      />
    </div>
    <ModalPopup ref="upsertMenuSection">
      <UpsertMenuSection
        :menu-section="menuSection"
        :menu-id="menuId"
        @create-menu-section="createMenuSection"
        @update-menu-section="updateMenuSection"
        @hide-modals="hideModals"
      />
    </ModalPopup>
  </div>
</template>

<script>
import contentPlaceholder from '@/components/layout/ContentPlaceholder.vue'
import UpsertMenuSection from '@/views/components/menuSection/UpsertMenuSection.vue'
import Pagination from '@/components/common/Pagination.vue'
import Draggable from 'vuedraggable'
import MenuSectionResource from '@/resources/menuSection.resource'
import MenuSection from '@/models/menuSection'
import ModalPopup from '@/components/common/ModalPopup.vue'

export default {
  name: 'MenuSectionList',
  components: {
    ModalPopup,
    contentPlaceholder,
    UpsertMenuSection,
    Pagination,
    Draggable
  },
  props: {
    menuId: { type: String, required: true }
  },
  data () {
    return {
      loading: true,
      items: null,
      total: 0,
      menuSection: null,
      paging: { page: 1, limit: 100, sort: 'position', sortType: 'asc' },
      showPagination: false,
      menuSectionResource: new MenuSectionResource(),
      orderedList: null
    }
  },
  mounted () {
    this.selectItems()
  },
  methods: {
    async selectItems () {
      await this.getMenuMenuSections()
    },
    async getMenuMenuSections () {
      const response = await this.menuSectionResource.getMenuMenuSections(this.menuId, this.paging)
      if (response) {
        this.loading = false
        this.items = response.data
        this.total = response.total
        if (this.total > this.paging.limit) {
          this.showPagination = true
        }
      }
    },
    async createMenuSection (menuSectionData) {
      try {
        const response = await this.menuSectionResource.createMenuSection(menuSectionData)
        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'));
          return
        }
        this.hideModals()
        this.appendItemOnList(response.data)
      } catch (e) {
        await this.$notice.alert(this.$t('messages.errorMessage'));
      }
    },
    async updateMenuSection (menuSectionData) {
      try {
        const response = await this.menuSectionResource.updateMenuSection(menuSectionData)
        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'))
          return false
        }
        this.hideModals()
        this.updateItemOnList(response.data)
      } catch (e) {
        await this.$notice.alert(this.$t('messages.errorMessage'))
      }
    },
    async deleteMenuSection (menuSection) {
      await this.$notice.confirm(this.$t('messages.areYouSure')).then(async (res) => {
        if (res) {
          try {
            const response = await this.menuSectionResource.deleteMenuSection(menuSection.id)
            if (!response) {
              await this.$notice.alert(this.$t('messages.errorMessage'))
              return
            }
            await this.$notice.toast.success(this.$t('messages.deletedMessage'));
            this.hideModals()
            this.removeItemFromList(menuSection)
          } catch (e) {
            await this.$notice.alert(this.$t('messages.errorMessage'))
          }
        }
      })
    },
    selectItem (menuSection) {
      this.menuSection = new MenuSection(menuSection)
      this.$refs.upsertMenuSection.openModal()
    },
    enableCreate () {
      this.menuSection = null
      this.menuSection = new MenuSection()
      this.$refs.upsertMenuSection.openModal()
    },
    hideModals () {
      this.$refs.upsertMenuSection.closeModal()
    },
    setPage (page) {
      this.paging.page = page
      this.selectItems()
    },
    showOrderButton() {
      return !!this.orderedList
    },
    moved (evt, updatedList) {
      this.orderedList = updatedList
    },
    async updatePositions() {
      if (!this.orderedList) {
        return;
      }
      try {
        for (const key in this.orderedList) {
          const item = this.orderedList[key]
          item.position = key
          await this.menuSectionResource.updateMenuSection(item)
        }
        await this.$notice.toast.info(this.$t('messages.updateMessage'))
        this.orderedList = null
      } catch (e) {
        console.warn({ e })
        await this.$notice.alert(this.$t('messages.errorMessage'))
      }
    }
  }
};
</script>

<style scoped>
.table > :not(:first-child) {
  border: none;
}
.table-row  {
  border-bottom: 1px solid #D5D8DC !important;
}
.table-row:hover {
  background-color: #E5E4E2;
}
.col > .btn {
  margin-bottom: 0;
}
.title {
  padding-left: 8px;
}
</style>

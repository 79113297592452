<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6>{{ $t('messages.contact') }}</h6>
    </div>
    <div class="card-body">
      <TavernoForm
        v-slot="{errors}"
        :validation-schema="contactSchema"
        type="POST"
        @submit="setContact"
      >
        <div class="form-group">
          <Field
            v-model="computedAddress"
            name="address"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.address}"
            :placeholder="$t('messages.address')"
            aria-label="Address"
          />
          <ErrorMessage
            class="text-danger small"
            name="address"
          />
        </div>
        <div class="form-group">
          <Field
            v-model="computedCity"
            name="city"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.city}"
            :placeholder="$t('messages.city')"
            aria-label="City"
          />
          <ErrorMessage
            class="text-danger small"
            name="city"
          />
        </div>
        <div class="form-group">
          <Field
            v-model="computedState"
            name="state"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.state}"
            :placeholder="$t('messages.state')"
            aria-label="State"
          />
          <ErrorMessage
            class="text-danger small"
            name="state"
          />
        </div>
        <div class="form-group">
          <Field
            v-model="computedCountry"
            name="country"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.country}"
            :placeholder="$t('messages.country')"
            aria-label="Country"
          />
          <ErrorMessage
            class="text-danger small"
            name="country"
          />
        </div>
        <div class="form-group">
          <Field
            v-model="computedPostalCode"
            name="postalCode"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.postalCode}"
            :placeholder="$t('messages.postalCode')"
            aria-label="Postal Code"
          />
          <ErrorMessage
            class="text-danger small"
            name="postalCode"
          />
        </div>
        <div class="form-group">
          <Field
            v-model="computedEmail"
            name="email"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.email}"
            :placeholder="$t('messages.email')"
            aria-label="Email"
          />
          <ErrorMessage
            class="text-danger small"
            name="email"
          />
        </div>
        <div class="form-group">
          <Field
            v-model="computedPhone"
            name="phone"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.phone}"
            :placeholder="$t('messages.phone')"
            aria-label="Phone"
          />
          <ErrorMessage
            class="text-danger small"
            name="phone"
          />
        </div>
        <div class="form-group">
          <Field
            v-model="computedWhatsapp"
            name="whatsapp"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.whatsapp}"
            :placeholder="$t('messages.whatsapp')"
            aria-label="Whatsapp"
          />
          <ErrorMessage
            class="text-danger small"
            name="whatsapp"
          />
        </div>
        <div class="form-group">
          <Field
            v-model="computedFacebook"
            name="facebook"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.facebook}"
            :placeholder="$t('messages.facebook')"
            aria-label="Facebook"
          />
          <ErrorMessage
            class="text-danger small"
            name="facebook"
          />
        </div>
        <div class="form-group">
          <Field
            v-model="computedInstagram"
            name="instagram"
            type="text"
            class="form-control"
            :class="{'is-invalid':errors.instagram}"
            :placeholder="$t('messages.instagram')"
            aria-label="Instagram"
          />
          <ErrorMessage
            class="text-danger small"
            name="instagram"
          />
        </div>
        <div class="text-center">
          <button
            type="submit"
            role="button"
            class="my-4 mb-2 btn btn-primary"
          >
            <mdicon name="content-save-check-outline" />
            &nbsp;{{ $t('messages.save') }}
          </button>
          <a
            role="button"
            class="my-4 mb-2 mx-2 btn btn-success"
            @click="cancel"
          >
            <mdicon name="content-save-off-outline" />
            &nbsp;{{ $t('messages.cancel') }}
          </a>
        </div>
      </TavernoForm>
    </div>
  </div>
</template>

<script>
import { Form as TavernoForm, Field, ErrorMessage } from 'vee-validate'
import { object, string } from 'yup'
import Contact from '@/models/contact'
import ContactResource from '@/resources/contact.resource'

export default {
  name: 'TavernContact',
  components: { TavernoForm, Field, ErrorMessage },
  props: {
    tavernId: { type: String, required: true }
  },
  emits: ['hide-modals'],
  data () {
    const contactSchema = object({
      address: string().required(),
      city: string().optional(),
      state: string().optional(),
      country: string().optional(),
      postalCode: string().optional(),
      email: string().email().optional(),
      phone: string().optional(),
      whatsapp: string().optional(),
      facebook: string().url().optional(),
      instagram: string().url().optional()
    })
    return {
      contactSchema,
      contact: new Contact(),
      contactResource: new ContactResource()
    }
  },
  computed: {
    computedAddress: {
      get: function () {
        return this.contact?.address ? this.contact.address : ''
      },
      set: function (address) {
        return address
      }
    },
    computedCity: {
      get: function () {
        return this.contact?.city ? this.contact.city : ''
      },
      set: function (city) {
        return city
      }
    },
    computedState: {
      get: function () {
        return this.contact?.state ? this.contact.state : ''
      },
      set: function (state) {
        return state
      }
    },
    computedCountry: {
      get: function () {
        return this.contact?.country ? this.contact.country : ''
      },
      set: function (country) {
        return country
      }
    },
    computedPostalCode: {
      get: function () {
        return this.contact?.postalCode ? this.contact.postalCode : ''
      },
      set: function (postalCode) {
        return postalCode
      }
    },

    computedEmail: {
      get: function () {
        return this.contact?.email ? this.contact.email : ''
      },
      set: function (email) {
        return email
      }
    },
    computedPhone: {
      get: function () {
        return this.contact?.phone ? this.contact.phone : ''
      },
      set: function (phone) {
        return phone
      }
    },
    computedWhatsapp: {
      get: function () {
        return this.contact?.whatsapp ? this.contact.whatsapp : ''
      },
      set: function (whatsapp) {
        return whatsapp
      }
    },
    computedFacebook: {
      get: function () {
        return this.contact?.facebook ? this.contact.facebook : ''
      },
      set: function (facebook) {
        return facebook
      }
    },
    computedInstagram: {
      get: function () {
        return this.contact?.instagram ? this.contact.instagram : ''
      },
      set: function (instagram) {
        return instagram
      }
    }

  },
  mounted () {
    this.getContact()
  },
  methods: {
    async getContact () {
      const contact = await this.contactResource.getTavernContact(this.tavernId)
      if (contact) {
        this.contact = new Contact(contact)
      }
    },
    async setContact (addressData) {
      try {
        let contact = {
          tavernId: this.tavernId,
          address: addressData.address,
          city: addressData.city !== '' ? addressData.city : null,
          state: addressData.state !== '' ? addressData.state : null,
          country: addressData.country !== '' ? addressData.country : null,
          postalCode: addressData.postalCode !== '' ? addressData.postalCode : null,
          email: addressData.email !== '' ? addressData.email : null,
          phone: addressData.phone !== '' ? addressData.phone : null,
          whatsapp: addressData.whatsapp !== '' ? addressData.whatsapp : null,
          facebook: addressData.facebook !== '' ? addressData.facebook : null,
          instagram: addressData.instagram !== '' ? addressData.instagram : null
        }

        let response = null
        if (this.contact.id) {
          contact.id = this.contact.id
          response = await this.contactResource.updateContact(contact)
        } else {
          response = await this.contactResource.createContact(contact)
        }

        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'));
          return
        }
        await this.$notice.toast.info(this.$t('messages.successMessage'));
        this.$emit('hide-modals')
      } catch (e) {
        await this.$notice.alert(this.$t('messages.errorMessage'));
        console.warn(e)
      }
    },
    cancel () {
      this.$emit('hide-modals')
    }
  }
}
</script>

<style scoped>

</style>

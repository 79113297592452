<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4" />
    <div class="row">
      <div class="card">
        <div class="pb-0 card-header">
          <div class="row">
            <div class="col-lg-8 p-3 d-flex justify-content-between">
              <h6 class="mb-2">
                {{ $t('messages.users') }}
              </h6>
            </div>
            <div class="col-lg-4 d-flex align-items-center mr-4">
              <button
                class="btn mb-0 btn-success ms-auto"
                @click="enableCreate"
              >
                <mdicon name="account-plus-outline" />
                &nbsp;{{ $t('messages.createUser') }}
              </button>
            </div>
          </div>
        </div>
        <contentPlaceholder v-if="loading" />
        <div
          v-else
          class="table"
        >
          <div class="table-row">
            <div class="px-2 py-1 row">
              <div class="col">
                <p class="mb-0 text-sm font-weight-bold">
                  {{ $t('messages.name') }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div
              v-for="currentUser in items"
              :key="currentUser.id"
            >
              <div class="table-row">
                <div class="px-2 py-1 row">
                  <div class="col">
                    <h6 class="mb-0 text-sm">
                      {{ currentUser.name }}
                    </h6>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <a
                      class="btn btn-link text-success"
                      :title="$t('messages.editUser')"
                      @click="selectItem(currentUser)"
                    >
                      <mdicon name="account-edit-outline" />
                    </a>
                    <a
                      class="btn btn-link text-success"
                      :title="$t('messages.userTaverns')"
                      :href="`/${baseDir}/user/${currentUser.id}/taverns`"
                    >
                      <mdicon name="store-outline" />
                    </a>
                    <a
                      class="btn btn-link text-danger"
                      :title="$t('messages.deleteUser')"
                      @click="deleteUser(currentUser)"
                    >
                      <mdicon name="account-off-outline" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        v-if="showPagination"
        v-model="paging.page"
        :loading="loading"
        :total-rows="total"
        :per-page="paging.limit"
        class="mt-3 mb-3"
        :value="paging.page"
        @set-page="setPage"
      />
    </div>
    <ModalPopup
      id="create"
      ref="createUser"
    >
      <CreateUser
        :user="user"
        @create-user="createUser"
        @hide-modals="hideModals"
      />
    </ModalPopup>
    <ModalPopup
      id="update"
      ref="updateUser"
    >
      <UpdateUser
        :user="user"
        @update-user="updateUser"
        @hide-modals="hideModals"
      />
    </ModalPopup>
  </div>
</template>

<script>
import contentPlaceholder from '@/components/layout/ContentPlaceholder.vue'
import CreateUser from '@/views/components/user/CreateUser.vue'
import UpdateUser from '@/views/components/user/UpdateUser.vue'
import User from '@/models/user.js'
import Pagination from '@/components/common/Pagination.vue'
import UserResource from '@/resources/user.resource'
import ModalPopup from '@/components/common/ModalPopup.vue'

export default {
  name: "UserList",
  components: {
    ModalPopup,
    contentPlaceholder,
    CreateUser,
    UpdateUser,
    Pagination
  },
  data() {
    return {
      loading: true,
      items: null,
      total: 0,
      user: null,
      paging: { page: 1, limit: 20, sort: 'createdAt', sortType: 'desc' },
      showPagination: false,
      userResource: new UserResource()
    }
  },
  mounted () {
    this.getUsers()
  },
  methods: {
    async getUsers () {
      const response = await this.userResource.getUsers(this.paging)
      if (response) {
        this.loading = false
        this.items = response.data
        this.total = response.total
        if (this.total > this.paging.limit) {
          this.showPagination = true
        }
      }
    },
    async createUser (userData) {
      try {
        const response = await this.userResource.createUser(userData)
        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'));
          return
        }
        this.hideModals()
        this.appendItemOnList(response.data)
      } catch (e) {
        await this.$notice.alert(this.$t('messages.errorMessage'));
      }
    },
    async updateUser (userData) {
      try {
        const response = await this.userResource.updateUser(userData)
        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'))
          return false
        }
        this.hideModals()
        this.updateItemOnList(response.data)
      } catch (e) {
        await this.$notice.alert(this.$t('messages.errorMessage'))
      }
    },
    async deleteUser (user) {
      await this.$notice.confirm(this.$t('messages.areYouSure')).then(async (res) => {
        if (res) {
          try {
            const response = await this.userResource.deleteUser(user.id)
            if (!response) {
              await this.$notice.alert(this.$t('messages.errorMessage'))
              return
            }
            await this.$notice.toast.success(this.$t('messages.deletedMessage'))
            this.hideModals()
            this.removeItemFromList(user)
          } catch (e) {
            await this.$notice.alert(this.$t('messages.errorMessage'))
          }
        }
      })
    },
    selectItem (user) {
      this.user = new User(user)
      this.$refs.updateUser.openModal()
    },
    enableCreate () {
      this.user = new User()
      this.$refs.createUser.openModal()
    },
    hideModals () {
      this.$refs.createUser.closeModal()
      this.$refs.updateUser.closeModal()
    },
    setPage (page) {
      this.paging.page = page
      this.getUsers()
    }
  }
}
</script>

<style scoped>
.table :not(:first-child) {
  border: none;
}
.table-row {
  border-bottom: 1px solid #D5D8DC !important;
}
.table-row:hover {
  background-color: #E5E4E2;
}
.col > .btn {
  margin-bottom: 0;
}
</style>

<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6>
        {{ $t('messages.createUser') }}
      </h6>
    </div>
    <div class="card-body">
      <TavernoForm
        v-slot="{errors}"
        :validation-schema="userSchema"
        type="POST"
        @submit="updateUser"
      >
        <div class="form-group">
          <label class="form-label">{{ $t('messages.name') }}</label>
          <Field
            ref="name"
            v-model="computedUserName"
            name="name"
            type="text"
            class="form-control"
            :value="user.name"
            :class="{'is-invalid':errors.name}"
            :placeholder="$t('messages.name')"
            aria-label="Name"
          />
          <ErrorMessage
            class="text-danger small"
            name="name"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('messages.email') }}</label>
          <Field
            v-model="computedUserEmail"
            name="email"
            type="email"
            class="form-control"
            :value="user?.email"
            :class="{'is-invalid':errors.email}"
            :placeholder="$t('messages.email')"
            :disabled="!admin"
            aria-label="Email"
          />
          <ErrorMessage
            class="text-danger small"
            name="email"
          />
        </div>
        <div
          v-if="user?.createdAt"
          class="row"
        >
          <label>{{ $t('messages.createdDate') }}: <span class="fw-light">{{ user?.createdAt }}</span></label>
        </div>
        <div class="text-center row">
          <div class="col">
            <button
              role="button"
              class="my-4 mb-2 btn btn-primary"
            >
              <mdicon name="content-save-check-outline" />
              &nbsp;&nbsp;{{ $t('messages.save') }}
            </button>

            <a
              role="button"
              class="my-4 mb-2 mx-2 btn btn-success"
              @click="cancel"
            >
              <mdicon name="content-save-off-outline" />
              &nbsp;{{ $t('messages.cancel') }}
            </a>
          </div>
        </div>
      </TavernoForm>
    </div>
  </div>
</template>

<script>
import { Form as TavernoForm, Field, ErrorMessage } from 'vee-validate'
import { object, string } from 'yup'
import { UserStatus, UserType } from '@/utils/enums/userEnum.js'
import User from '@/models/user.js'

export default {
  name: 'UpsertUser',
  components: { TavernoForm, Field, ErrorMessage },
  props: { user: User },
  emits: ['create-user', 'update-user', 'hide-modals'],
  data () {
    const userSchema = object({
      name: string().required(this.$t('messages.errors.requiredField'))
    })
    return {
      userSchema,
      statusList: UserStatus,
      typeList: UserType,
      bkUser: this.user
    }
  },
  computed: {
    computedUserEmail: {
      get: function () {
        return this.user?.email
      },
      set: function (email) {
        return email
      }
    },
    computedUserName: {
      get: function () {
        return this.user?.name
      },
      set: function (name) {
        return name
      }
    }
  },
  methods: {
    async updateUser (userData) {
      let user = {
        id: this.user.id,
        name: userData.name
      }
      this.$emit('update-user', user)
    },
    cancel () {
      window.location.reload()
    }
  }
}
</script>
